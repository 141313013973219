import { TerminalUI } from "./TerminalUI";
import io from "socket.io-client";

const search = location.search.substring(1);
let urlParams = {};
if (search) {
  urlParams = JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}');
}

// console.log(urlParams.auth_key_staff);

if (urlParams.auth_key_staff) {
  localStorage.setItem('auth_key', urlParams.auth_key_staff);
  location.href = location.href.replace(new RegExp("&auth_key_staff=([^&]*)", 'i'), "");
} else if (urlParams.auth_key_student) {
  localStorage.setItem('auth_key', urlParams.auth_key_student);
  location.href = location.href.replace(new RegExp("&auth_key_student=([^&]*)", 'i'), "");
}

// TODO: Keep these variables in .env file
const serverAddress = "https://lab.itvedant.com:2000/";
// const serverAddress = "http://localhost:2000/";
// const pulseApiUrl = "https://pulseapi.traininglab.dev:8890/index.php";
const pulseApiUrl = "https://api.itvedant.com";

function connectToSocket(serverAddress) {
  return new Promise(res => {
    const socket = io(serverAddress);
    res(socket);
  });
}

function startTerminal(container, socket) {
  const terminal = new TerminalUI(socket);
  terminal.attachTo(container);
  terminal.startListening();
}

function start(username) {
  const container = document.getElementById("terminal-container");

  connectToSocket(serverAddress).then(socket => {

    if (search) {
      socket.emit("user", username);
    }

    startTerminal(container, socket);
  });
}

async function getUserDetails(courseId) {
  const res = await fetch(`${pulseApiUrl}/v4/student/is-big-data-user?course_id=${courseId}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('auth_key')}`
    }
  });

  const data = await res.json();

  if (data.allowed) {
    start(data.username);
  } else {
    alert('You are not allowed to perform this action.');
    // window.close();
  }
  // console.log(data);
}

if (urlParams.course_id) {
  getUserDetails(urlParams.course_id);
} else {
  alert('course_id field is required.');
}

// Better to start on DOMContentLoaded. So, we know terminal-container is loaded
// start();
