import { Terminal } from "xterm";
import { FitAddon } from 'xterm-addon-fit';
import "xterm/css/xterm.css";

export class TerminalUI {
  constructor(socket) {
    this.terminal = new Terminal({
        /** You can make your terminals colorful */
        theme: {
          background: "#202B33",
          foreground: "#F5F8FA"
        },
        convertEol: true,
        cols: 80,
        rows: 30
    });
    this.fitAddon = new FitAddon();
    this.terminal.loadAddon(this.fitAddon);

    this.socket = socket;
  }

  /**
   * Attach event listeners for terminal UI and socket.io client
   */
  startListening() {
    this.terminal.onData(data => this.sendInput(data));
    this.socket.on("output", data => {
      // When there is data from PTY on server, print that on Terminal.
      if (data == '\r\u001b[K') {
        // "\033[F" – move cursor to the beginning of the previous line
        // "\033[A" – move cursor up one line
        this.write(data.replace('\r\u001b', '\x1b33[A'));
      } else if (data.includes('\r\n')) {
        this.write(data);
      } else if (data.includes('\r')) {
        this.write(data.replace('\r', '\r\n'));
      } else {
        this.write(data);
      }
    });
  }

  /**
   * Print something to terminal UI.
   */
  write(text) {
    this.terminal.write(text);
  }

  /**
   * Utility function to print new line on terminal.
   */
  prompt() {
    this.terminal.write(`\r\n$ `);
  }

  /**
   * Send whatever you type in Terminal UI to PTY process in server.
   * @param {*} input Input to send to server
   */
  sendInput(input) {
    this.socket.emit("input", input/* .replace(/\r(?!\n)/g, '\r\n') */);
  }

  /**
   *
   * @param {HTMLElement} container HTMLElement where xterm can attach terminal ui instance.
   */
  attachTo(container) {
    this.terminal.open(container);
    this.fitAddon.fit();

    this.terminal.onResize(function (evt) {
      // this.socket.send({ rows: evt.rows });
      // console.log(evt);

      const terminal_size = {
        Width: evt.cols,
        Height: evt.rows,
      };
      this.socket.send("\x04" + JSON.stringify(terminal_size));
    });

    const xterm_resize_ob = new ResizeObserver(function (entries) {
      // console.log(entries);
      // since we are observing only a single element, so we access the first element in entries array
      try {
        this.fitAddon && this.fitAddon.fit();
      } catch (err) {
        console.log(err);
      }
    });
    
    // start observing for resize
    xterm_resize_ob.observe(container);

    // let input = "";
    // let cursor = 0;
    // this.terminal.onKey((event) => {
    //   console.log(event, event.key.substr(1));
    //   const code = event.key.charCodeAt(0);
    //   if(code == 27){   //Backspace
    //     // this.terminal.write("\x1b[D");
    //     /* if (event.key.substr(1) == '[C') {
    //       if (cursor < input.length) {
    //         cursor += 1;
    //         this.terminal.write(event.key);
    //       }
    //     } else {
    //       input = input.substr(0, cursor) + 
    //               event.key + 
    //               input.substr(cursor);
    //     } */
    //   }
    // })

    // Default text to display on terminal.
    this.terminal.write("Terminal Connected");
    this.terminal.write("");
    this.prompt();
  }

  clear() {
    this.terminal.clear();
  }
}
